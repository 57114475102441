import React from 'react';
import './App.css';
import { useSubmitGameActionMutation } from './services/api'


function App() {
  const [trigger, res] = useSubmitGameActionMutation()
  return (
    <div className="App">
      <button onClick={() => trigger({ type: 'noop' })}>{"Test Action"}</button>
      <pre>
        {JSON.stringify(res.data, null, 2)}
      </pre>
    </div>
  );
}

export default App;
